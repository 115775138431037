<template>
  <div class="page-lock">
    <!-- <toolbar /> -->
    <div>
      <img src="../assets/images/lock.png" />
      <div class="lock-wrapper">
        <h4>没有权限</h4>
        <p>您的账号没有权限。</p>
        <p>如需使用纵购公众号管理系统，请联系超级管理员配置账号权限。</p>
        <p>联系电话：400-930-2128</p>
        <div class="options">
          <yos-button class="green" @click="onReLogin">重新登录</yos-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { removeAccessToken } from '../util'
export default {
  setup() {
    const router = useRouter()

    const onReLogin = () => {
      removeAccessToken()
      router.replace('/login')
    }

    return {
      onReLogin
    }
  }
}
</script>

<style lang="postcss">
.page-lock {
  padding: 80px;
  position: relative;

  .lock-wrapper {
    position: absolute;
    top: 250px;
    left: 735px;
    width: 500px;
    height: 292px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    line-height: 2;
    padding: 20px 30px;

    h4 {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 30px;
    }

    .options {
      text-align: center;
      margin-top: 40px;
    }
  }
}
</style>
